import { createDrawerNavigator } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'

import { useColor, useLayout } from 'core'
import { ChatRoutes } from 'navigation/routes'

import { ChatConversation } from 'routes/Chat/Conversation'

import { AppSideBar } from './SideBar'
import { DrawerToggle } from './SideBar/DrawerToggle'

export type ChatAppRoutesProps = {
  [ChatRoutes.Root]?: undefined
  [ChatRoutes.Conversation]: {
    id: string
    fromNewChat?: boolean
    fromStrict?: boolean
    model?: string
  }
}

export type ChatAppModalRoutesProps = {
  [ChatRoutes.ModalMobileMenu]?: undefined
  [ChatRoutes.ModalUpdateConversation]: {
    conversationId: string
  }
  [ChatRoutes.ModalDeleteConversation]: {
    conversationId: string
  }
}

const ChatAppStack = createDrawerNavigator<ChatAppRoutesProps>()

export const ChatApp = observer(function ChatApp() {
  /** States. */
  const { color } = useColor()
  const { screenSize, breakPoints, spacing } = useLayout()

  const showDrawerPermanent = screenSize.width >= breakPoints.lg

  return (
    <ChatAppStack.Navigator
      initialRouteName={ChatRoutes.Conversation}
      screenOptions={({ navigation }) => ({
        headerShown: false,
        headerStyle: {
          backgroundColor: color['utility-surface-2'],
        },
        headerTintColor: color['content-1'],
        headerTitleAlign: 'center',
        headerShadowVisible: false,

        drawerType: 'back',
        // drawerStyle: {
        //   width: showDrawerPermanent ? 280 : 320,
        //   backgroundColor: color['utility-surface-2'],
        //   borderRightColor: color['utility-border-1'],
        //   padding: spacing[2],
        // },
        drawerContentStyle: {
          backgroundColor: color['utility-surface-2'],
        },
        sceneContainerStyle: {
          backgroundColor: color['utility-surface-2'],
        },
      })}
    >
      {/* Conversation. */}
      <ChatAppStack.Screen
        name={ChatRoutes.Conversation}
        component={ChatConversation}
        getId={({ params }) => {
          if (!params?.id) return
          return `${ChatRoutes.Conversation}-${params.id}`
        }}
      />
    </ChatAppStack.Navigator>
  )
})
