import { useCallback, useEffect, useState } from 'react'
import Constants from 'expo-constants'
import { Audio } from 'expo-av'
import { observer } from 'mobx-react-lite'

import { ButtonIcon } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useRootStore } from 'store/root.store'

import { showSnackbar } from '../Snackbar'

type StreamPlaybackProps = {
  messageId: string
}

const API_URL = Constants.expoConfig?.extra?.apiUrl

export const StreamPlayback = observer(function StreamPlayback({ messageId }: StreamPlaybackProps) {
  /** States. */
  const { authStore } = useRootStore()
  const [playback, setPlayback] = useState<Audio.Sound | null>(null)
  const { color } = useColor()
  const [isPlaying, setIsPlaying] = useState(false)
  const [audioLoading, setAudioLoading] = useState(false)

  const createAudio = useCallback(async () => {
    setAudioLoading(true)

    const { sound } = await Audio.Sound.createAsync({
      uri: `${API_URL}/v1/public/chat/message/${messageId}/audio/stream?auth_token=${authStore.authToken}`,
    })
    setPlayback(sound)

    try {
      await sound.playAsync()
      setAudioLoading(false)
      setIsPlaying(true)
    } catch {
      // This is just to ensure error thrown on `playAsync` upon unmounting does not block UI.
      // No need to handle this error specifically.
    }
  }, [messageId, authStore.authToken])

  useEffect(() => {
    return playback
      ? () => {
          playback.unloadAsync().catch(() => {})
        }
      : undefined
  }, [playback])

  const onPlayAudio = () => {
    playback?.playAsync().then((status) => {
      if (status.isLoaded) {
        setIsPlaying(status.isPlaying)
      }
    })
  }

  const onPauseAudio = () => {
    playback?.pauseAsync().then((status) => {
      if (status.isLoaded) {
        setIsPlaying(status.isPlaying)
      }
    })
  }

  const onRewindAudio = () => {
    playback?.replayAsync().then((status) => {
      if (status.isLoaded) {
        setIsPlaying(true)
      }
    })
  }

  if (!playback || audioLoading) {
    return (
      <ButtonIcon
        kind="minimal"
        iconName="slow-motion-video"
        iconColor={audioLoading ? color.accent.brand : color['content-2']}
        onPress={() => {
          showSnackbar({
            label: 'Loading the audio',
          })
          createAudio()
        }}
        loading={audioLoading}
      />
    )
  }

  return (
    <>
      <ButtonIcon
        kind="minimal"
        iconName="replay"
        iconColor={color['content-2']}
        onPress={onRewindAudio}
        disabled={!playback}
      />
      <ButtonIcon
        kind="minimal"
        iconName={isPlaying ? 'pause-circle' : 'play-circle'}
        iconColor={color.accent.brand}
        onPress={isPlaying ? onPauseAudio : onPlayAudio}
        disabled={!playback}
      />
    </>
  )
})
