import { StyleSheet } from 'react-native'
import { LinkingOptions, NavigationContainer } from '@react-navigation/native'
import { QueryClientProvider } from 'react-query'
import Toast from 'react-native-toast-message'
import * as Linking from 'expo-linking'
import { observer } from 'mobx-react-lite'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import { BlockView, useLayout, useSnackbarConfig } from 'core'
import { useRootStore } from 'store/root.store'
import { reactQueryClient } from 'api/query-client'

import { AuthProvider } from './auth-provider'
import {
  AccountRoutes,
  AuthenticationRoutes,
  CampaignRoutes,
  ChatRoutes,
  ContributeRoutes,
  HowToModalRoutes,
  PaymentRoutes,
} from './routes'
import { RootApp } from './stack/root'

export const AppNavigator = observer(function AppNavigator() {
  /** States. */
  const { storeHydrated } = useRootStore()
  const { screenSize, breakPoints } = useLayout()
  const snackbarConfig = useSnackbarConfig()

  const showToastOnBottom = screenSize.width >= breakPoints.lg

  if (!storeHydrated) {
    return null
  }

  return (
    <>
      <SafeAreaProvider>
        <NavigationContainer linking={navigationLinking}>
          <QueryClientProvider client={reactQueryClient}>
            <AuthProvider>
              <RootApp />
            </AuthProvider>
          </QueryClientProvider>

          {/* Wrapping this and hide the overscroll to prevent a weird white space on the bottom on Web. */}
          <BlockView pointerEvents="none" style={[StyleSheet.absoluteFill, { overflow: 'hidden' }]}>
            <Toast
              config={snackbarConfig}
              position={showToastOnBottom ? 'bottom' : 'top'}
              {...(showToastOnBottom ? { bottomOffset: 24 } : { topOffset: 24 })}
            />
          </BlockView>
        </NavigationContainer>
      </SafeAreaProvider>
    </>
  )
})

const DEFAULT_PREFIX = Linking.createURL('')
const navigationLinking: LinkingOptions<any> = {
  prefixes: [DEFAULT_PREFIX],
  config: {
    screens: {
      // [AuthenticationRoutes.Login]: 'sign-in/:method?',
      // [AuthenticationRoutes.VerifyCode]: 'verify-code',

      /** Chat App. */
      [ChatRoutes.Root]: {
        screens: {
          [ChatRoutes.Conversation]: 'chat/conversation/:id?',
        },
      },
      [ChatRoutes.ModalMobileMenu]: 'chat/mobile-menu',
      // [ChatRoutes.ModalUpdateConversation]: 'chat/conversation/:conversationId/update',
      // [ChatRoutes.ModalDeleteConversation]: 'chat/conversation/:conversationId/delete',

      /** Contribute App. */
      // [ContributeRoutes.Root]: {
      //   screens: {
      //     [ContributeRoutes.DTV]: 'contribute/dtv',
      //     [ContributeRoutes.WeAreAllSatoshi]: 'contribute/we-are-all-satoshi',
      //     [ContributeRoutes.FudBuster]: 'contribute/fud-buster',
      //     [ContributeRoutes.NoSecondBest]: 'contribute/no-second-best',
      //     [ContributeRoutes.Statistics]: 'contribute/statistics',
      //     [ContributeRoutes.Leaderboard]: 'contribute/leaderboard',
      //   },
      // },
      // Contribute Modals.
      // [ContributeRoutes.ModalDiscardDTVPrompt]: 'contribute/dtv/discard-prompt',

      // How To Modals.
      // [HowToModalRoutes.HowStatisticsWork]: 'contribute/how-statistics-work',
      // [HowToModalRoutes.HowToCleanupData]: 'contribute/how-to-cleanup-data',
      // [HowToModalRoutes.HowToFightFud]: 'contribute/how-to-fight-fud',
      // [HowToModalRoutes.HowToReplyAsSatoshi]: 'contribute/how-to-reply-as-satoshi',
      // [HowToModalRoutes.HowToNoSecondBest]: 'contribute/how-to-no-second-best',

      /** Payment App. */
      // [PaymentRoutes.AddCreditModal]: 'payment/add-credit',
      // [PaymentRoutes.WithdrawBalance]: 'payment/withdraw-balance',
      // [PaymentRoutes.GetDonationModal]: 'payment/get-donation',
      //
      // /** Account App. */
      // [AccountRoutes.MyAccount]: 'account/:tab?',
      // [AccountRoutes.ConnectEmail]: 'account/connect-email',
      // [AccountRoutes.ConnectLightning]: 'account/connect-lightning',
      // [AccountRoutes.ConnectNostr]: 'account/connect-nostr',
      // [AccountRoutes.VerifyCode]: 'account/verify-code',
      //
      // /** Campaign App. */
      // [CampaignRoutes.Geyser]: 'campaign/geyser',
    },
  },
}
