import { request } from 'api/request'

import { ConversationReaction } from './get-conversation-messages'

export const sendFeedback = (messageId: string, reaction: ConversationReaction) => {
  return request.putWithRawResponse(`v1/public/chat/message/${messageId}/feedback`, {
    data: {
      reaction,
    },
  })
}
