import { request } from 'api/request'

type FeatureFlag = {
  enabled: boolean
}
export type GetAccountMeData = {
  id: string
  fullName: string | null
  email: string | null
  twitterUsername: string | null
  hasLightningAuth: boolean
  hasNostrAuth: boolean
  isAnonymous: boolean
  tcAcceptedAt: string | null
  credits: number
  balance: {
    earning: number
    total: number
  }
  type: 'USER' | 'CONTRIBUTOR'
  featureFlags?: {
    CHAT_SHOW_SELECT_MODEL?: FeatureFlag
    CONTRIBUTION_NO_SECOND_BEST?: FeatureFlag
    CHAT_PLAYBACK_AUDIO?: FeatureFlag
    DISPLAY_GEYSER_POPUP?: FeatureFlag
  } | null
}

export const getAccountMe = async (): Promise<GetAccountMeData> => {
  const account = await request.get('v1/public/account/me')

  console.log(account, 'account-------')

  if (account.jwt)
    sessionStorage.setItem('auth-token', account.jwt)

  return {
    balance: {},
  } as any
}

// export const getAccountMe = (): GetAccountMeData => {
//   return {
//     balance: {},
//   } as any
// }
