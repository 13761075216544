import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'

import { AnimateTransition, BlockView, Card2, ChatInput, ChatOutLoading, ChatOutput, useLayout, UserAvatar } from 'core'
import { useRootStore } from 'store/root.store'

import { ConversationMessage } from 'api/chat/get-conversation-messages'

import { CreditNotice } from './CreditNotice'

type NewConversationProps = {
  isUserOutOfCredit: boolean
  optimisticUserMessage: ConversationMessage | null
  optimisticSosMessage: ConversationMessage | null

  userFullName: string | null
  messageStreamLoading: boolean
  setMessage: (message: string) => void

  selectedModel?: string | null
}

const REGULAR_QUICK_PROMPTS = [
  'Does bitcoin have inflation?',
  'How is bitcoin different from crypto?',
  'Can I buy a fraction of a bitcoin?',
  'Why does bitcoin use energy?',
  'How do I safely store my bitcoin?',
  'How is bitcoin different from gold?',
  'How does bitcoin mining stabilize energy grids?',
  'How does bitcoin mining reduce global methane emissions?',
]


export const NewConversation = observer(function NewConversation({
  isUserOutOfCredit,
  optimisticUserMessage,
  optimisticSosMessage,

  userFullName,
  messageStreamLoading,
  setMessage,
  selectedModel,
}: NewConversationProps) {
  /** States. */
  const { uiToggleStore } = useRootStore()
  const { screenSize, spacing } = useLayout()

  // Quick Prompts.
  if (!optimisticUserMessage && !optimisticSosMessage) {
    return (
      <BlockView
        flex
        size={{ width: '100%', maxWidth: 745 }}
        vAlign="flex-end"
        padding={{ horizontal: screenSize.width <= 745 ? spacing[2] : 0 }}
      >
        {!!isUserOutOfCredit && uiToggleStore.outOfCreditNotice && (
          <AnimateTransition key="chat-out-of-credit-notice" type="from-bottom" style={{ marginBottom: spacing[2] }}>
            <CreditNotice />
          </AnimateTransition>
        )}

        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            alignItems: 'stretch',
            justifyContent: 'center',
          }}
          style={{ flexGrow: 0 }}
        >
          {REGULAR_QUICK_PROMPTS.map((prompt, index) => {
            const lastItem = REGULAR_QUICK_PROMPTS.length - 1 === index
            return (
              <BlockView
                key={`quick-prompt-${index}`}
                flex
                margin={{ right: lastItem ? 0 : spacing[1] }}
                size={{ maxWidth: 200 }}
              >
                <Card2
                  kind="outlined"
                  subtitle={prompt}
                  subtitleStrong
                  wrapperStyle={{ flex: 1 }}
                  containerStyle={{ flex: 1 }}
                  onCardPress={() => setMessage(prompt)}
                />
              </BlockView>
            )
          })}
        </ScrollView>
      </BlockView>
    )
  }

  // Optimistic Messages.
  return (
    <BlockView
      flex
      size={{ width: '100%', maxWidth: 745 }}
      padding={{ horizontal: screenSize.width <= 745 ? spacing[2] : 0 }}
    >
      {!!optimisticUserMessage && (
        <AnimateTransition key="new-chat-optimistic-user-message" type="from-bottom">
          <ChatInput message={optimisticUserMessage.text} avatar={<UserAvatar fullName={userFullName} />} />
        </AnimateTransition>
      )}
      {/* Message Loading. */}
      {messageStreamLoading && <ChatOutLoading />}
      {!!optimisticSosMessage && (
        <AnimateTransition key="new-chat-optimistic-sos-message" type="from-bottom">
          <ChatOutput
            conversationId=""
            messageId={optimisticSosMessage.id}
            message={optimisticSosMessage.text}
            showReaction={false}
            reaction={null}
            replySource={null}
            streamText
            showPlayback={false}
            voiceUrl={null}
            selectedModel={selectedModel}
          />
        </AnimateTransition>
      )}
    </BlockView>
  )
})
